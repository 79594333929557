import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "welcome-page"
};
const _hoisted_2 = {
  class: "welcome-cover"
};
const _hoisted_3 = {
  key: 3,
  class: "invite-bonus-wrap"
};
const _hoisted_4 = {
  class: "invite-bonus-info"
};
const _hoisted_5 = {
  class: "highlight"
};
const _hoisted_6 = {
  class: "welcome-wrap"
};
const _hoisted_7 = {
  class: "btn-wrap flex justify-center"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  key: 0,
  class: "bonus-wrap"
};
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import GuestLogin from './componets/GuestLogin';
import Login from './componets/Login';
import Register from './componets/Register';
import ForgetPassword from './componets/ForgetPassword';
import { queryWelcomeData, saveInviteCode } from '@/api';
export default {
  __name: 'welcome',
  setup(__props) {
    const route = useRoute();
    const showLogin = ref(false);
    const showRegister = ref(false);
    const showForgetPwd = ref(false);
    const showService = ref(false);
    const registerCode = ref('');
    const welcomeData = ref({});
    onMounted(() => {
      var _route$query, _route$query2;
      registerCode.value = decodeURIComponent(decodeURIComponent((_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.code));
      let action = decodeURIComponent(decodeURIComponent((_route$query2 = route.query) === null || _route$query2 === void 0 ? void 0 : _route$query2.action));
      if (action === 'register') {
        showRegister.value = true;
      }
      if (registerCode.value) {
        saveCode();
      }
      queryWelcomeData().then(res => {
        welcomeData.value = res.data || {};
      });
    });
    const saveCode = () => {
      saveInviteCode({
        code: registerCode.value
      }).then(res => {});
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_Service = _resolveComponent("Service");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [showRegister.value ? (_openBlock(), _createBlock(_unref(Register), {
        key: 0,
        code: registerCode.value,
        onOnClose: _cache[0] || (_cache[0] = $event => showRegister.value = false)
      }, null, 8, ["code"])) : showForgetPwd.value ? (_openBlock(), _createBlock(_unref(ForgetPassword), {
        key: 1,
        onOnClose: _cache[1] || (_cache[1] = $event => showForgetPwd.value = false)
      })) : showLogin.value ? (_openBlock(), _createBlock(_unref(Login), {
        key: 2,
        registerReward: welcomeData.value.registerReward,
        onOnClose: _cache[2] || (_cache[2] = $event => showLogin.value = false),
        onOnShowForgetPwd: _cache[3] || (_cache[3] = $event => showForgetPwd.value = true),
        onOnShowRegister: _cache[4] || (_cache[4] = $event => showRegister.value = true)
      }, null, 8, ["registerReward"])) : _createCommentVNode("", true), welcomeData.value.joinUsReward ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.$t('welcome.joinRewrad.content1')) + " ", 1), _createElementVNode("span", _hoisted_5, _toDisplayString(welcomeData.value.joinUsReward) + "PHP", 1), _createTextVNode(" " + _toDisplayString(_ctx.$t('welcome.joinRewrad.content2')) + " " + _toDisplayString(_ctx.$t('welcome.joinRewrad.content3')), 1)])])) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "service-btn",
        onClick: _cache[5] || (_cache[5] = $event => showService.value = true)
      }, [_createVNode(_component_van_image, {
        class: "service-btn-icon",
        fit: "contain",
        src: require('@/assets/img/user/service.png')
      }, null, 8, ["src"]), _createElementVNode("div", null, _toDisplayString(_ctx.$t('livechat.service')), 1)])), [[_directive_audio]]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_image, {
        class: "tonny-img",
        src: require('@/assets/gif/welcome/tonny-chopper.gif')
      }, null, 8, ["src"]), _createVNode(_component_van_image, {
        class: "welcome-img",
        src: require('@/assets/img/user/welcome.png')
      }, null, 8, ["src"]), _createVNode(_component_van_image, {
        class: "spin-img",
        src: require('@/assets/gif/welcome/spin-wheel.gif')
      }, null, 8, ["src"]), _createVNode(_component_van_image, {
        class: "boat-img",
        src: require('@/assets/gif/welcome/thousand-sunny.gif')
      }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_7, [_withDirectives((_openBlock(), _createElementBlock("div", {
        class: "action-btn flex",
        onClick: _cache[6] || (_cache[6] = $event => showLogin.value = true)
      }, [_createElementVNode("img", {
        class: "action-btn-icon more-right",
        src: require('@/assets/img/user/user.png')
      }, null, 8, _hoisted_8), _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.login')), 1)])), [[_directive_audio]]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "action-btn flex",
        onClick: _cache[7] || (_cache[7] = $event => showRegister.value = true)
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/img/user/phone.png')
      }, null, 8, _hoisted_9), _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.register')), 1), welcomeData.value.registerReward ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('welcome.registerRewrad')) + " " + _toDisplayString(welcomeData.value.registerReward) + "PHP", 1)) : _createCommentVNode("", true)])), [[_directive_audio]])]), _createVNode(_unref(GuestLogin)), _createVNode(_component_Service, {
        show: showService.value,
        onOnClose: _cache[8] || (_cache[8] = $event => showService.value = false)
      }, null, 8, ["show"])])]);
    };
  }
};