import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { storage } from '@/utils/storage';
import ws from '@/connect';
import { useUserStore } from '@/store';
import { guestLogin } from "@/api";
export default {
  __name: 'GuestLogin',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const router = useRouter();
    const userStore = useUserStore();
    const doGuestLogin = () => {
      let guestId = storage.get('guestId');
      if (window.$bridge) {
        window.$bridge.callHandler('getData', 'guestId', result => {
          guestId = result;
        });
      }
      guestLogin({
        guestId
      }).then(res => {
        let userData = res.data || {};
        if (window.$bridge) {
          window.$bridge.callHandler('saveData', JSON.stringify({
            key: 'guestId',
            value: userData.guestId
          }));
        } else {
          storage.set('guestId', userData.guestId);
        }
        userStore.setUserInfo(userData);
        userStore.setLoginInit(true);
        ws.connect();
        goToHome();
      });
    };
    const goToHome = () => {
      router.push({
        name: 'game'
      });
    };
    return (_ctx, _cache) => {
      const _directive_audio = _resolveDirective("audio");
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "guest-btn",
        onClick: doGuestLogin
      }, [_createTextVNode(_toDisplayString(_ctx.$t('user.guestLogin')), 1)])), [[_directive_audio]]);
    };
  }
};